import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// routes
import SlideShow from '../components/SlideShow.vue'
import AboutPage from '../components/AboutPage.vue'
import Bibliography from '../components/Bibliography.vue'
import ArchiveInventoryList from '../components/ArchiveInventoryList.vue'
import CatalyticTexts from '../components/CatalyticTexts.vue'
import FreePublications from '../components/FreePublications.vue'
import Contact from '../components/Contact.vue'
import PurchasePublications from '../components/PurchasePublications.vue'
import Press from '../components/Press.vue'

export const routes = [
    { path: '/', component: SlideShow, props: true },
    { path: '/index.html', component: SlideShow, props: true },
    { path: '/about', component: AboutPage },
    { path: '/bibliography', component: Bibliography },
    { path: '/biblio.html', component: Bibliography },
    { path: '/archiveinventory', component: ArchiveInventoryList },
    { path: '/catalytictexts', component:  CatalyticTexts },
    { path: '/freepublications', component: FreePublications },
    { path: '/contact', component: Contact },
    { path: '/purchase', component: PurchasePublications },
    { path: '/press', component: Press }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
