<template>
<header>
    <div class="header">
        <div class="title">
            <router-link to="/"> {{ title }} </router-link>
        </div>
        <div class="title">
            {{ description }}
        </div>
    </div>
    <div class="nav-bar">
        <div class="nav-item" v-for="item in nav" :key="item.index">
            <router-link v-if="item.vueRoute" :to="'/' + item.url"> {{ item.text }} </router-link>
            <a v-else :href="item.url" target="_blank"> {{ item.text }} </a>
        </div>
    </div>
</header>
</template>

<script>
    export default {
        props: ['title', 'description'],
        data() {
            return {
                nav: [
                    {
                        text: 'about',
                        url: 'about',
                        vueRoute: true
                    },
                    {
                        text: 'bibliography',
                        url: 'bibliography',
                        vueRoute: true
                    },
                    {
                        text: 'antinomian press archive inventory list',
                        url: 'archiveinventory',
                        vueRoute: true
                    },
                    {
                        text: 'catalytic texts',
                        url: 'catalytictexts',
                        vueRoute: true
                    },
                    {
                        text: 'free ap publications',
                        url: 'freepublications',
                        vueRoute: true
                    },
                    {
                        text: 'purchase ap publications',
                        url: 'https://www.kinmont.com/pdfs/AP publications for sale.pdf',
                        vueRoute: false
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        flex-wrap: wrap;
    }
    .nav-bar {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .nav-bar div {
        margin-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: 515px) {
        .nav-bar {
            flex-direction: column;
        }
        .nav-item {
            text-align: left!important;
        }
    }
    @media (max-width: 810px) {
        .nav-item {
            flex: 30%
        }
        .nav-item:nth-child(3),
        .nav-item:nth-child(6) {
            text-align: right;
        }
    }
    @media (max-width: 753px) {
        .nav-item {
            flex: 40%
        }
        .nav-item:nth-child(3){
            text-align: left;
        }
        .nav-item:nth-child(2),
        .nav-item:nth-child(4) {
            text-align: right;
        }
    }

    a {
        font-size: 10pt;
        color:	#444F51;
        font-family: Calibri, Arial;
        text-decoration: none;
        white-space: nowrap;
    }
    a:hover	{
        /* color:	#D81E05; */
        text-decoration: underline;
    }
    a:visited {
        color:	#444F51;
        text-decoration: none;
    }
    a:active {
        color:	#D81E05;
        text-decoration: none;
    }
</style>