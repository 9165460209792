<template>
    <div>
        <input type=text v-model="search" placeholder="filter by keyword">
        <h2 v-if="search.length > 0">
            {{ filteredList.length }} {{ filteredList.length === 1 ? 'item' : 'items' }} found containing '{{ search }}'
        </h2>
        <div class="group" v-for="(group, index) in groupedList" :key="index">
            <span class="title">{{ group[0].year }}</span>
            <div class="item" v-for="(item, index) in group" :key="index">
                <div class="item-title" v-html="(item.number !== undefined) ? item.number  + '. ' + item.title : item.title"></div>
                <div class="item-description">
                    <span v-html="item.description"></span>
                    <div v-if="item.downloadLinks.length > 0" class="item-link">
                        <span v-if="item.downloadLinks.length !== 1"> download </span>
                        <span  v-for="(link, index) in item.downloadLinks" :key="index" >
                            &nbsp;
                            <a :href="link" target="_blank">
                                <span v-if="item.downloadLinks.length === 1"> download </span>
                                <span v-else> {{ index + 1 }}</span>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: '',
            list: [],
            resultsClass: 'hidden'
        };
    },
    props: [ 'jsonFile' ],
    computed: {
        filteredList: function() {
            return this.list.filter((item) => {
                return item.title.toUpperCase().match(this.search.toUpperCase()) ||
                       item.description.toUpperCase().match(this.search.toUpperCase());
            });
        },
        groupedList() {
            return groupByKeepOrder(_.orderBy(this.filteredList, ['number', 'year', 'month', 'day'], ['desc', 'desc', 'desc', 'desc']), 'year');
        }
    },
    created() {
        this.$http.get(this.jsonFile + `?t=${new Date().getTime()}`)
            .then(response => response.json())
            .then(data => this.list = data[Object.keys(data)[0]])
            .then(() => this.resultsClass = '');
    }
}
import _ from 'lodash'
/**
  * @description Groups items from an array into an array of objects, grouped by a property 'prop' name, maintaining original order. Based on functionality of LoDash's 'groupBy' function, but, unlike LoDash, preserves original array's order and returns an array instead of an object.
  * @param arr {array of objects} - Objects within array should contain a property marked by the 'prop' argument, or else they will be excluded from the output and a warning will be logged.
  * @param prop {string} Propery to use for grouping. The value of this will be converted to a string when creating group names.
  */
var groupByKeepOrder = function(arr, prop) {
    var newArr = [], // array to return, keeps track of order
        wrapObj = {}; // temporary object used for grouping

    _.forEach(arr, function (item) {
        // gets property name to group by and converts it to a string for grouping purposes
        var propName = item[prop];
        if(propName) {
            propName = propName.toString();

            // checks if group exists already and creates a new group if not, pushing it into the array to return ('newArr')
            if (!wrapObj[propName]) {
                wrapObj[propName] = [];
                newArr.push(wrapObj[propName]);
            }

            // adds item to the group
            wrapObj[propName].push(item);
        }
        else {
            console.warn("utils -> groupByKeepOrder", "Property '" + prop + "' not found within object. It will be ignored from the output.", item);
        }
    });

    //delete wrapObj; // don't need this anymore
    return newArr
}
</script>

<style scoped>
    input {
        width: 100%;
        box-sizing: border-box;
        border-width: 1px;
        border-style: none;
        border-bottom-style: solid;
        border-color: lightgray;
    }
    input:focus {
        /* border-style: none; */
        border-color: darkgray;
        outline: none;
    }
    .group {
        margin-top: 10px;
    }
    .item-description {
        padding: 10px;
        /* background-color: lightgray; */
        /* box-shadow:0px 1px 0px darkgrey; */
        /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%); */
    }
    .item-description >>> p:first-child {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    .item-description >>> p:last-child {
        /* margin-block-start: 0px; */
        margin-block-end: 0px;
    }
    .item-title {
        margin-top: 1em;
        color: #bf3ee8;
        font-weight: bold;
        /* margin-bottom: 1em; */
    }
    .item-link {
        margin-top: .5em;
        /* float: right; */
        text-align: right;
        color: #444F51!important;
        /* color: #14A3DD!important; */
        /* font-weight: bold; */
    }
    .item-link a {
        color: #444F51!important;
        /* color: #14A3DD; */
    }
    .hidden {
        display: none;
        transition: ease-in-out;
        transition-duration: 1s;
    }
    .item {
                /* background-color: lightgray; */
        /* box-shadow: 5px 5px 5px darkgrey; */
        /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%); */
    }

</style>