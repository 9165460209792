<template>
    <div>
        <span class="title">CONTACT</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>