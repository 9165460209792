<template>
    <div>
        <span class="title">BIBLIOGRAPHY</span>
        <ul>
            <li>The Antinomian Press bibliography can be downloded <a href="./pdfs/AP_Bibliography.pdf" target="_blank">here</a>.</li>
            <li>A list of publications available for purchase can be viewed <a href='https://www.kinmont.com/pdfs/AP publications for sale.pdf' target="_blank">here</a>.</li>
        </ul>
        <br><br><br><br><br><br><br><br>
        <!-- <item-list jsonFile="./data/biblio.json"></item-list> -->
    </div>
</template>

<script>
// import ItemList from './ItemList.vue'

export default {
    data() {
        return {}
    },
    components: {
        // 'item-list': ItemList
    }
}
</script>

<style scoped>

</style>