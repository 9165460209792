<template>
<div class="content">
    <span class="title">ABOUT</span>
    <div class="flex-container">

        <div>
            <img src="images/Ben_printing_at_Section_7.JPG" alt="Ben printing at Section 7" class="flex-image">
            <div class="caption">
                Ben Kinmont printing <i>PROJECT SERIES: Lee Lozano</i> as part of an Antinomian Press live printing and distribution event at Section 7 in Paris in April of 2016. The broadside on the table is <i>THE ANTINOMIAN PRESS: Sometimes you must close a door to enter another room</i>, which reprints a poem and engraving from Benjamin Spencer's <i>Chrysomeson, a golden meane</i> (London, 1659).
            </div>
        </div>
        <div class="flex-text">
            <p>In 1995, I started the Antinomian Press due to my unhappiness with representation in the gallery system. I was also interested in artists taking greater control in the understanding of their work through curatorial and publishing activities.</p>

            <p>About ten years earlier, I had studied mid-17th-century English radical literature. The groups that I looked at included the Ranters, Levellers, and Diggers, all of whom were antinomians who fought against centralized power and argued for individual freedom. Because of the collapse of censorship during the periods of Civil War and Interregnum, the antinomians were able to “turn the world upside down” through pamphleteering and giving a voice to the needs and beliefs of England’s poorer population. They were also accused of being anarchists.</p>

            <p>The Antinomian Press is an agency to distribute books and ephemera in support of project work. I have used it to make exhibition catalogues, to let people know about my own projects, to present the work of others, and to collaborate with my students while teaching. When the event is live, the publications are distributed for free; afterwards, they are sold to cover their cost of production. The Antinomian Press publisher’s mark is a 17th-century representation of an antinomian and it comes from Benjamin Spencer’s <i>Chrysomeson, a golden meane</i> (London, 1659).</p>

            <p>Ben Kinmont, 2021</p>

            <ul>
                <li>To learn about Kinmont’s work and exhibition history, visit his gallery page at <a href="http://www.airdeparis.com/artists/ben-kinmont/" target="_blank">Air de Paris</a>. </li>
                <li>For information on Kinmont’s projects, visit his project site <a href="https://benkinmont.com" target = "_blank">here</a>. </li>
                <li>Ben Kinmont Bookseller, a.k.a. <i>Sometimes a nicer sculpture is to be able to provide a living for your family</i>, can be found <a href="https://kinmont.com" target="_blank">here</a>. </li>
            </ul>
            <div class="antinomian">
                <img src="images/bk_about.jpg" width="80" alt="Antinomian">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.flex-container {
    display: flex;
}
.flex-image {
    padding-right: 1em;
    padding-top: 1em;
    width: 425px;
}
.caption {
    font-size: 10px;
    margin-right: 1em;
}

.antinomian {
    float: right;
    text-align: right;
    align-items: flex-end;
    padding-top: 40px;
}
@media (max-width: 810px) {
    .flex-container {
        flex-direction: column;
    }
    .flex-image {
        width: 100%;
    }
    .flex-text {
        width: 100%;
    }
}
</style>