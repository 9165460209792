import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueResource from 'vue-resource';
Vue.config.productionTip = false;
Vue.use(router);
Vue.use(VueResource);

// Transpiler for older browsers
import 'core-js/stable';
// import '@babel/polyfill';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')