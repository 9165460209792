<template>
<footer>
    <div class="nav-bar">
        <div class="nav-item" v-for="item in nav" :key="item.index">
            <a v-if="item.url != ''" :href="item.url"> {{ item.text }} </a>
            <span v-else>{{ item.text }}</span>
        </div>
    </div>
</footer>
</template>

<script>
    export default {
        data() {
            return {
                nav: [
                    { text: 'antinomian press', url: ''},
                    { text: '684 north main street, sebastopol, ca 95472', url: ''},
                    { text: 't 707 829 8715', url: ''},
                    { text: 'm 917 669 6157', url: ''},
                    { text: 'bkinmont@gmail.com', url: 'mailto:bkinont@gmail.com'}
                ]
            }
        }
    }
</script>

<style scoped>
    footer {
        padding-top: 15px;
    }
    .header {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        flex-wrap: wrap;
    }
    .nav-bar {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .nav-bar div {
        margin-left: 10px;
        margin-right: 10px;
    }
    .nav-item {
        font-size: 10pt;
        color:	#444F51;
        font-family: Calibri, Arial;
    }
    @media (max-width: 577px) {
        .nav-bar {
            flex-direction: column;
        }
        .nav-item {
            text-align: left!important;
        }
        .nav-item:nth-child(3) {
            order: 3;
        }
    }
    @media (max-width: 810px) {
        .nav-item {
            flex: 40%
        }
        .nav-item:nth-child(2),
        .nav-item:nth-child(5) {
            text-align: right;
        }
        .nav-item:nth-child(4) {
            order: 3;
        }
    }
    @media (max-width: 753px) {
        .nav-item {
            flex: 40%
        }
        .nav-item:nth-child(3){
            text-align: left;
        }
        .nav-item:nth-child(2){
            text-align: right;
        }
        .nav-item:nth-child(4) {
            order: 3;
        }
    }

    a {
        font-size: 10pt;
        color:	#444F51;
        font-family: Calibri, Arial;
        text-decoration: none;
        white-space: nowrap;
    }
    a:hover	{
        color:	#D81E05;
        text-decoration: underline;
    }
    a:visited {
        color:	#444F51;
        text-decoration: none;
    }
    a:active {
        color:	#D81E05;
        text-decoration: none;
    }
</style>