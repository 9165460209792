<template>
    <div>
        <span class="title">ARCHIVE INVENTORY LIST</span>
        <ul>
            <li>The Antinomian Press archive is in the collection of The Museum of Modern Art and parts of it can be viewed on their website <a href="https://www.moma.org/collection/works/associatedworks/165826" target="_blank">here.</a></li>
            <li>The archive's inventory list can be viewed <a href="./pdfs/Antinomian_Press_Archive_Inventory_List.pdf" target="_blank">here.</a></li>
        </ul>
        <br><br><br><br><br><br><br><br>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script> 