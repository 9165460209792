<template>
    <div>
        <span class="title">CATALYTIC TEXTS</span>
        <ul><li>Catalytic texts are the flyers and statements which were written to cause something to happen during a project. They are available for free below. Their descriptions refer to the first appearance of each text as part of one of Kinmont’s projects. They were almost exclusively distributed for free at public events, whether on the street or at a public lecture. Some flyers were reprinted and reused in later iterations. To learn more about how the texts were used, view Kinmont’s project descriptions <a href="https://benkinmont.com/projects" target="_blank">here.</a> </li></ul>
        <item-list jsonFile="./data/cat_txt.json"></item-list>
    </div>
</template>

<script>
import ItemList from './ItemList.vue'

export default {
    data() {
        return {

        }
    },
    components: {
        'item-list': ItemList
    }
}
</script>

<style scoped>
    input {
        width: 100%;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: light-gray;
    }
    .item-description {
        padding: 10px;
        background-color: lightgray;
    }
    .item-description >>> p:first-child {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    .item-description >>> p:last-child {
        margin-block-end: 0px;
    }
    .item-title {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .item-link {
        margin-top: .5em;
        text-align: right;
        color: #14A3DD!important;
        font-weight: bold;
    }
    .item-link a {
        color: #14A3DD;
    }
    .hidden {
        display: none;
        transition: ease-in-out;
        transition-duration: 1s;
    }

</style>