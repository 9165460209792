<template>
    <div class="image-container">
        <img 
            v-for="image in imageList"
            class="show"
            :key="image.index" 
            :src="image.url" 
            :z-index="image.sortOrder" 
            :id="'slide-' + image.sortOrder">
        <!-- invisible image to fix absolute position formatting -->
        <img src='images/slider1.png' class="hide" style="position:relative">
    </div>
</template>

<script>
export default {
    props: [
        'playSlideShow'
    ],
    data() {
        return {
            imageList: [
                { url: 'images/slider1.png', altText: '', sortOrder: '0' },
                { url: 'images/slider2.png', altText: '', sortOrder: '1' },
                { url: 'images/slider3.png', altText: '', sortOrder: '2' },
                { url: 'images/slider4.png', altText: '', sortOrder: '3' },
                { url: 'images/slider5.png', altText: '', sortOrder: '4' },
                { url: 'images/slider6.png', altText: '', sortOrder: '5' },
                { url: 'images/slider7.png', altText: '', sortOrder: '6' },
                { url: 'images/slider8.png', altText: '', sortOrder: '7' },
                { url: 'images/slider9.png', altText: '', sortOrder: '8' },
                { url: 'images/slider10.png', altText: '', sortOrder: '9' },
                { url: 'images/slider11.png', altText: '', sortOrder: '10' },
                { url: 'images/slider12.png', altText: '', sortOrder: '11' },
                { url: 'images/slider13.png', altText: '', sortOrder: '12' },
                { url: 'images/slider14.png', altText: '', sortOrder: '13' },
                { url: 'images/slider15.png', altText: '', sortOrder: '14' }
            ],
            currentImage: 0,
            showIsRunning: this.playSlideShow
        }
    },
    methods: {
        showImage(image) {
            document.querySelector('#slide-' + image).classList.add('show');
            document.querySelector('#slide-' + image).classList.remove('hide');
        },
        hideImage(image) {
            document.querySelector('#slide-' + image).classList.remove('show');
            document.querySelector('#slide-' + image).classList.add('hide');
        },
        cycleImages() {
            if (this.currentImage < this.imageList.length) {
                for(let i = 0; i < this.imageList.length; i++) {
                    this.hideImage(i);
                }
                this.showImage(this.currentImage);
                this.currentImage++;
            }
            else {
                this.currentImage = 0;
            }
        },
        runShow() {
            this.showIsRunning = true;
            this.cycleImages();
            setTimeout(() => {
                if (this.showIsRunning) {
                    this.runShow();
                }
            }, 5000);
        }
    },
    mounted() {
        this.runShow();
     }
}
</script>

<style scoped>
    .image-container {
        position: relative;
    }
    img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
    }
    img.show {
        opacity: 1;;
        transition: ease-in-out;
        transition-duration: 1s;
    }
    img.hide {
        opacity: 0;;
        transition: ease-in-out;
        transition-duration: 1s;
    }
</style>