<template>
<div class="page-container">
  <site-header title="ANTINOMIAN PRESS" description="A PUBLISHING PROJECT BY BEN KINMONT"/>
  <div class="page-content clearfix">
    <router-view></router-view>
  </div>
  <site-footer></site-footer>
  <!-- <div class="footer"> footer information </div> -->
</div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
  name: 'App',
  data() {
    return {
      contentHeight: '0px'
    }
  },
  components: {
    SiteHeader,
    SiteFooter
  }
}
</script>

<style>
  .page-container {
    max-width: 1200px;
    margin: auto;
  }
  .page-content {
    max-width: 1200px;
    /* min-height: 360px; */
    margin: 10px ;
    font-size: 10pt;
    color:	#444F51;
    font-family: Calibri, Arial;
  }

  .footer{
    position: relative;
  }
  .clearfix::after {
    content: " ";
    clear: both;
    display: block;
    position:relative;
  }
  .title {
      font: bold 10pt Calibri, Arial;
      color: #bf3ee8;
  }
  .title a {
      color: #bf3ee8 !important;
  }
  .content {
    text-align: justify;
  }
  a {
    color: #444F51;
  }
  a:visited {
    color: #444F51;
  }
  .col-3 {

  }

</style>
