<template>
    <div>
        <span class="title">FREE AP PUBLICATIONS</span>
        <ul>
            <li>The Antinomian Press publications listed here can be viewed and downloaded for free. The description of each refers to the first printing of the text (not the web edition). The publications in the <i>Project Series</i> were written by Kinmont, often include interviews, and are mostly brief studies of other artists and their work. The <i>Student Series</i> texts emerged out of Kinmont’s teaching activities with various students in France and the United States and include student project descriptions. </li>
            <li>The catalytic texts can be downloaded <router-link to="./catalytictexts">here</router-link> & the Antinomian Press bibliography can be downloaded <a href="https://antinomianpress.org/pdfs/AP_Bibliography.pdf" target="_blank">here</a>.</li>
        </ul>
        <item-list jsonFile="./data/free_pubs.json"></item-list>
        <p class="title">External Sites with free Antinomian Press publications:</p>
        <p><a href="https://caveat.be/produsers/ben-kinmont.html" target="_blank">Caveat, featuring <i>The Materialization of Life into alternative economies, Promised  relations: or, thoughts concerning a few artists' contracts</i>, and <i>Project Series:  Archive Contracts</i></a></p>
        <p><a href="http://www.airdeparis.com/download/benkinmont/" target="_blank">Air de Paris featuring <i>Project Series: Chrisopher d’Arcangelo</i>, <i>Project Series: Archive Contracts</i>, and “Ben Kinmont: Open by chance & appointment,” in <i>Sans Niveau ni Mètre</i></a></p>
        <p><a href="https://whitney.org/exhibitions/2014-biennial/ben-kinmont" target="_blank">The Whitney Museum featuring the complete archive of <i>Sshh</i> as of the Whitney Biennial in 2014</a></p>

    </div>
</template>

<script>
import ItemList from "./ItemList.vue"

export default{
    data() {
        return {}
    },
    components: { ItemList }
}
</script>