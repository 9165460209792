<template>
    <div>
        <span class="title">THIS WILL BE A LINK TO A PDF</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>